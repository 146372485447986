import React, { useState,useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import ContentMap from '@solid-ui-components/ContentMap'

// import Technologies from '@solid-ui-blocks/Technologies/Block01'
import BuildBrand from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block01'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Stats from '@solid-ui-blocks/Stats/Block01'
import Strategies from '@solid-ui-blocks/Features/Block06'
import Download from '@solid-ui-blocks/CallToAction/Block02'
import Testimonials from '@solid-ui-blocks/Testimonials/Block02'
import GetStarted from '@solid-ui-blocks/CallToAction/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Team from '@solid-ui-blocks/Team/Block01'

import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import HowItWorks from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import GoogleMap from '@solid-ui-components/ContentMap/ContentMap'
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const IndexPage = props => {
  const { allBlockContent } = props.data

  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: ""
  });

  const addData = (object) => {
    var axios = require('axios');
    var data = new FormData();
    var string = JSON.stringify(object)
    data.append('from', 'Triaxo Solutions <postmaster@sandboxeda9308605114da0af134936e11b1524.mailgun.org>');
    data.append('to', 'shehryarkn@gmail.com');
    data.append('subject', 'Triaxo Solutions <triaxo.com>');
    data.append('text', string);
    data.append('domain', " sandboxeda9308605114da0af134936e11b1524.mailgun.org")

    var config = {
      method: 'post',
      url: 'https://api.mailgun.net/v3/sandboxeda9308605114da0af134936e11b1524.mailgun.org/messages',
      headers: {
        'Authorization': 'Basic YXBpOjk5NTA0MzBhZDE3ZDNlZTIxZDdhZjFmNzFkOTFlMmEzLTFiOGNlZDUzLTMzZGFjM2E1',
        // ...data.getHeaders()
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const addForm = async () => {

    try {
      var response = await addData(contact);
      console.log("add", response)
      alert("Message Send Sucessfully")

    }
    catch (err) {
      console.log("error");
    }
  }
  const [screenWidth, setScreenWidth] = useState();
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const style = {
    div: {
      marginTop: "5px"
    },
    input: {
      boxSizing: "border-box",
      minWidth: "0",
      display: "block",
      width: "100%",
      padding: "0.7rem",
      fontSize: "inherit",
      lineHeight: "inherit",
      border: "1px solid",
      borderRadius: " 4px",
      color: "inherit",
      backgroundColor: "transparent",
      border: "none",
      backgroundColor: "#edf2f7",
      color: "#718096",
      outline: "none",
      borderRadius: "0.5rem",
      marginRight: "230px",
      fontFamily: "Poppins, sans-serif"
    },
    button: {
      color: "white",
      backgroundColor: "#38bdf8",
      padding: "15px",
      borderRadius: "50px",
      border: "0px",
      fontSize: "15px",
      marginLeft: "120px",
      marginTop: "8px",
      marginBottom: "30px"
    }
  }



  const defaultProps = {
    center: {
      lat: 30.341790357021434,
      lng: -97.75501897795894
    },
    zoom: 11
  }
  const headingStyle = {
    boxSizing: 'border-box',
    margin: "50px auto",
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 'bold',
    lineHeight: 1.55,
    color: 'var(--theme-ui-colors-heading, #2d3748)',
    textDecoration: 'none',
    fontSize: '2.25rem',

  };
  const rowStyle = {
    margin: "0px 30px",
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', 
    gap: '20px', 
  };
  const rowStyleSmall={
    margin: "0px 30px",
    display: 'grid',
    gridTemplateColumns: '1fr', 
    gap: '20px', 
  }
  const contactInfoStyle = {
    lineHeight: '1.5',
    fontSize: '1.2rem',
  };

  const phoneStyle = {
    color: '#2d3748',
    textDecoration: 'underline',
    cursor: 'pointer',
  };
  const addressStyle = {
    // Add styles for the address, if desired
    color: '#2d3748',

  };
  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='4' />
      <Divider space='4' />
      <Divider space='4' />
      <Blog content={content['latest-blogs']} />

      <div style={screenWidth > 600 ? rowStyle: rowStyleSmall}>
        <div >
          <form>
            <input style={style.input} type="text" name="name" onChange={(e) => { setContact({ ...contact, name: e.target.value }) }} placeholder="Full Name" />
            <br />
            <input style={style.input} type="email" name="email" onChange={(e) => { setContact({ ...contact, email: e.target.value }) }} placeholder="Email Address" />
            <br />
            <textarea style={style.input} name="message" onChange={(e) => { setContact({ ...contact, message: e.target.value }) }} placeholder="Enter Your Message"></textarea>
            <button style={style.button} type="button" onClick={addForm}>Send Message</button>
          </form>
        </div>
        <div>
          <div style={{ height: '100%', width: '100%',backgroundColor:"red" }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
      <Divider space='5' />
      <Blog content={content['heading']} />

      <div style={screenWidth > 600 ? rowStyle: rowStyleSmall}>
        <div style={{
  backgroundColor: "#718096",
  margin: screenWidth > 600 ? "0px 135px" : "0px",
  textAlign: "center",
  borderRadius: "15px",
  padding: "40px",
}}
>
          <h3 style={{ color: "white" }}>Head Office :</h3>
          <p style={contactInfoStyle}>
            <span style={phoneStyle} >
              +1 (512) 256-0433
            </span>
            <br />
            <br />
            <span style={addressStyle}>
              5900 Balcones Drive, STE 100
              <br />
              Austin, TX 78731
            </span>
          </p>
        </div>

        <div style={{
          backgroundColor: "#38bdf8",
          margin: screenWidth > 600 ? "0px 135px" : "0px",
          textAlign: " center",
          borderRadius: "15px",
          padding: "40px",
        }}>
          <h3 style={{ color: "white" }}>Pakitsan Office:</h3>
          <p style={contactInfoStyle}>
            <span style={phoneStyle} onClick={() => window.open('tel:+924232212700')}>
              (042) 32212700
            </span>
            <br />
            <br />
            <span style={addressStyle}>
              49 Aibak Block, Garden Town,<br></br> Lahore
            </span>
          </p>
        </div>

      </div>

      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query portifolioSiteBlockContent   {
    allBlockContent(
      filter: { page: { in: ["site/contact-us", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
